import React from 'react'
import s from './About.module.scss'
import aboutUsPic1 from '../../../assets/about us/illustration.png'
import aboutUsPic2 from '../../../assets/about us/illustration1.png'
import aboutUsPic3 from '../../../assets/about us/illustration2.png'
import { useTranslation } from 'react-i18next'
import taskBird from '../../../assets/about us/task.png'

export const About = () => {

    const { t } = useTranslation()

    return (
        <div className={s.about}>
            <div className={s.about_tittle}>{t('aboutTitle')}
            </div>
            <div className={s.about__text}>
                <p>{t('aboutText')}
                </p>
            </div>
            <div className={s.about_tittle}>{t('targetTitle')}</div>
            <div className={s.about__text}>
                {t('targetText')}
            </div>
            <div className={s.task_container}>
                <div className={s.task_img}>
                    <img src={taskBird} alt="" />
                </div>
                <div className={s.task_body}>
                <div className={s.task_title}>
                    {t('taskTitle')}
                </div>
                <div className={s.task_list}>
                    <ul className={s.task_ul}>
                        <li>{t('taskText_1')}</li>
                    </ul>
                    <ul className={s.task_ul}>
                        <li>{t('taskText_2')}</li>
                    </ul>
                    <ul className={s.task_ul}>
                        <li>{t('taskText_3')}</li>
                    </ul>
                    <ul className={s.task_ul}>
                        <li>{t('taskText_4')}</li>
                    </ul>
                    <ul className={s.task_ul}>
                        <li>{t('taskText_5')}</li>
                    </ul>
                </div>
                </div>
            </div>
            <div className={s.services_tittle}>
                <p>{t('valueTitle')}</p>
            </div>
            <div className={s.about__services}>
                <div className={s.service__card}>
                    <div className={s.card__img}><img src={aboutUsPic1} alt="" /></div>
                    <div className={s.card__text}>{t('value_1')}</div>
                </div>
                <div className={s.service__card}>
                    <div className={s.card__img}><img src={aboutUsPic2} alt="" /></div>
                    <div className={s.card__text}>{t('value_2')}</div>
                </div>
                <div className={s.service__card}>
                    <div className={s.card__img}><img src={aboutUsPic3} alt="" /></div>
                    <div className={s.card__text}>{t('value_3')}</div>
                </div>
            </div>
        </div>
    )
}
