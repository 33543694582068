import React, { useEffect, useRef, useState } from "react";
import s from '../TablePage.module.scss'
import { useDispatch, useSelector } from "react-redux";
import { fetchCollection } from "../../../store/collectionSlice";
import { Paginator } from "../../Paginator/Paginator";
import { Loader } from "../../Loader/Loader";
import { useTranslation } from "react-i18next";
import { RawOfTableSoundTrap } from "./RawOfTable/RawOfTablesoundTrap"
import { BoxInfo } from "./BoxInfo/BoxInfo"
import { getCsvLink, recognizeSoundTrapsFiles, uploadSoundTrapsFiles } from "../../../store/soundTrapsSlice";
//import { DateRangeWithTimePicker } from "../../DateRangePicker/DateRangePicker";


export const SoundTraps = () => {
    const dispatch = useDispatch()
    const { collection, status, error } = useSelector(state => state.collection)
    const { link } = useSelector(state => state.soundTraps)
    const { t } = useTranslation()
    const containerRef = useRef(null)
    const datePickerRef = useRef(null)
    const [isVisible, setIsVisible] = useState(false)
    const [isVisibleDatePicker, setIsVisibleDatePicker] = useState(false)
    const [visibleColumns, setVisibleColumns] = useState({
        date: true,
        GPS: true,
        temperature: true,
        trapName: true,
    })

    const toggleContainer = (event) => {
        event.stopPropagation()
        setIsVisible((prev) => !prev)
    }
    // const toggleDatePicker = (event) => {
    //     event.stopPropagation()
    //     setIsVisibleDatePicker((prev) => !prev)
    // }
    const handleClickOutside = (event) => {
        if (containerRef.current && !containerRef.current.contains(event.target)) {
            setIsVisible(false)
        }
        if (datePickerRef.current && !datePickerRef.current.contains(event.target)) {
            setIsVisibleDatePicker(false)
        }
    }

    useEffect(() => {
        const onClickOutside = (event) => handleClickOutside(event)
        if (isVisible || isVisibleDatePicker) {
            document.addEventListener("click", onClickOutside)
        }
        return () => document.removeEventListener("click", onClickOutside)
    }, [isVisible, isVisibleDatePicker])

    const handleCheckboxChange = (column) => {
        setVisibleColumns((prevState) => ({
            ...prevState,
            [column]: !prevState[column],
        }))
    }

    let raw = collection.map((b, index) => <RawOfTableSoundTrap key={index} {...b} visibleColumns={visibleColumns} />)
    const boxInfo = collection?.map((b, index) => <BoxInfo {...b} key={index} />)

    const selectMusic = (e) => {
        dispatch(uploadSoundTrapsFiles(e.target.files))
    }

    const getCsvFile = () => {
        dispatch(getCsvLink())
    }

    useEffect(() => {
        if (link) {
            const downloadLink = document.createElement('a')
            downloadLink.href = link
            downloadLink.download = 'file.csv'
            document.body.appendChild(downloadLink)
            downloadLink.click()
            document.body.removeChild(downloadLink)
        }
    }, [link])

    useEffect(() => {
        dispatch(fetchCollection('https://ml1.bird-trap-db.ssrlab.by/api/birds'))
    }, [dispatch])
    if (status === 'loading') {
        return <Loader />
    }

    const CheckboxContainer = () => {
        const handleContainerClick = (event) => {
            event.stopPropagation()
        }
        return (
            <div ref={containerRef} onClick={handleContainerClick} className={s.checkboxContainer}>
                <label>
                    <input
                        type="checkbox"
                        checked={visibleColumns.date}
                        onChange={() => handleCheckboxChange('date')}
                    />
                    {t('Date')}
                </label>
                <label>
                    <input
                        type="checkbox"
                        checked={visibleColumns.GPS}
                        onChange={() => handleCheckboxChange('GPS')}
                    />
                    {t('GPS')}
                </label>
                <label>
                    <input
                        type="checkbox"
                        checked={visibleColumns.temperature}
                        onChange={() => handleCheckboxChange('temperature')}
                    />
                    {t('Temperature')}
                </label>
                <label>
                    <input
                        type="checkbox"
                        checked={visibleColumns.trapName}
                        onChange={() => handleCheckboxChange('trapName')}
                    />
                    {t('Trap Name')}
                </label>
            </div>
        )
    }

    return (
        <div className={s.wrapper}>
            <div>
                <div className={s.tablePage__container}>
                    {error ? <h1>An error occured: {error}</h1> :
                        <>
                            <div className={s.table__main}>
                                <div className={s.tittle}>
                                    {t('soundTraps')}
                                </div>
                                <div className={s.buttons_box}>
                                    <div className={s.upload__buttons}>
                                        <label className={s.file_upload} htmlFor="uploadFile">{t('uploadFile')}</label>
                                        <input id="uploadFile" type="file" name="userfile[]" multiple onChange={(e) => selectMusic(e)} />

                                        <div className={s.post}>
                                            <button onClick={() => dispatch(recognizeSoundTrapsFiles())}
                                            >{t('startRecognition')}</button>
                                        </div>
                                    </div>
                                    <div className={s.filter_buttons}>
                                        <div className={s.show_but}>
                                            <div onClick={toggleContainer} className={s.filter_but}>Show columns</div>
                                            {isVisible && <CheckboxContainer />}
                                        </div>
                                        {/* <div className={s.show_but}>
                                            <div onClick={toggleDatePicker} className={s.filter_but}>Filter</div>
                                            {isVisibleDatePicker && <DateRangeWithTimePicker datePickerRef={datePickerRef}/>}
                                        </div> */}
                                        <div onClick={getCsvFile} className={s.filter_but}>
                                            Save as CSV
                                        </div>
                                    </div>
                                </div>
                                <div className={s.table__content}>
                                    <table className={s.table}>
                                        <thead>
                                            <tr>
                                                <td>id</td>
                                                {visibleColumns.date && <td>{t('date')}</td>}
                                                <td>{t('recordTime')}</td>
                                                {visibleColumns.GPS && <td>{t('GPS')}</td>}
                                                {visibleColumns.temperature && <td>{t('temperature')}</td>}
                                                {visibleColumns.trapName && <td>{t('trapName')}</td>}
                                                <td>{t('birdRecognized')}</td>
                                                <td>{t('sessionTime')}</td>
                                                <td>{t('status')}</td>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {raw}
                                        </tbody>
                                    </table>
                                    <div className={s.boxInfo}>
                                        {boxInfo}
                                    </div>
                                </div>
                                <Paginator
                                    url={'https://ml1.bird-trap-db.ssrlab.by/api/birds?page_num='} />
                            </div>
                        </>
                    }
                </div>
                <div className={s.upperCloud}></div>
                <div className={s.uuderCloud}></div>
            </div>
        </div>

    )
}