import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { setAuthStatus } from "./authSlice";
import { fetchCollection } from "./collectionSlice";

export const uploadSoundTrapsFiles = createAsyncThunk(
  'soundTraps/uploadsoundTRapsFiles',
  async (sounds, { rejectWithValue, dispatch }) => {
    const formData = new FormData()    
    for (const sound of sounds) {
      formData.append('files', sound)
    }
    
    try {
      const response = await fetch("https://ml1.bird-trap-db.ssrlab.by/api/uploadfiles", {
        method: "POST",
        headers: {
          Authorization: `Bearer ${localStorage.getItem('token')}`
        },
        redirect: 'follow',
        body: formData
      });
      if (!response.ok) {
        if (response.status === 401) {
          dispatch(setAuthStatus(false))
        }
        throw new Error(response.statusText)
      }
      const data = await response.json()
      dispatch(fetchCollection('https://ml1.bird-trap-db.ssrlab.by/api/birds'))
      return data
    } catch (error) {
      return rejectWithValue(error.message)
    }
  }
)

export const recognizeSoundTrapsFiles = createAsyncThunk(
  'soundTraps/recognizeSoundTRapsFiles',
  async (_, { rejectWithValue, dispatch }) => {    
    const body = {
      scheme: 'Bearer',
      credentials: `${localStorage.getItem('token')}`
    }
    try {
      const response = await fetch("https://ml1.bird-trap-db.ssrlab.by/api/runrecognition", {
        method: "POST",
        headers: {
          "Content-Type": "application/json"
        },
        body: JSON.stringify(body)
        
      })
      if (!response.ok) {
        if (response.status === 401) {
          dispatch(setAuthStatus(false))
        }
        throw new Error(response.statusText)
      }
      const data = await response.json()
      dispatch(fetchCollection('https://ml1.bird-trap-db.ssrlab.by/api/birds'))
      return data
    } catch (error) {
      return rejectWithValue(error.message)
    }
  }
)

export const getCsvLink = createAsyncThunk(
  'soundTraps/getCsvLink',
  async function (_, { rejectWithValue, dispatch }) {
     
    try {
      const response = await fetch(`https://ml1.bird-trap-db.ssrlab.by/api/birds-in-csv`, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('token')}`
        }
      })
      if (!response.ok) {
        if (response.status === 401) {
          
          dispatch(setAuthStatus(false));
        }
        const errorMessage = await response.json();
        throw new Error(errorMessage.detail);
      }
      const data = await response.json()
      return data
    } catch (error) {
      return rejectWithValue(error.message)
    }
  }
)

const recognizeSlice = createSlice({
  name: 'recognize',
  initialState: {
    error: null,
    status: null,
    link: null

  },
  reducers: {
    resetStatus: (state) => {
      state.status = null
    },
    changeLanguages: (state, action) => {
      state.language = action.payload
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(uploadSoundTrapsFiles.pending, (state) => {
        state.status = 'loading';
        state.error = null
      })
      .addCase(uploadSoundTrapsFiles.fulfilled, (state, action) => {
        state.status = 'succeeded';

      })
      .addCase(uploadSoundTrapsFiles.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.payload;
      })
      .addCase(getCsvLink.pending, (state) => {
        state.status = 'loading';
        state.error = null
      })
      .addCase(getCsvLink.fulfilled, (state, action) => {
        state.status = 'succeeded'
        state.link = action.payload
      })
      .addCase(getCsvLink.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.payload;
      })

  },
})

export const { resetStatus, changeLanguages } = recognizeSlice.actions
export default recognizeSlice.reducer

