import React from "react";
import s from './PrivacyPolicy.module.scss'
import { useTranslation } from "react-i18next"

export const PrivacyPolicy = () => {
    const { t } = useTranslation()
    return (
        <div className={s.wrapper}>
            <div className={s.wrapper_body}>
                <div className={s.container}>
                    <div className={s.about}>
                        <div className={s.about_tittle}>
                            <p>{t('privacyText1')}
                            </p>
                        </div>
                        <div className={s.about__text}>
                            <p>{t('privacyText2')}
                            </p>
                        </div>
                        <div className={s.task_list}>
                            <ul className={s.task_ul}>
                                <li>{t('privacyText3')}</li>
                            </ul>
                            <ul className={s.task_ul}>
                                <li>{t('privacyText4')}</li>
                            </ul>
                            <ul className={s.task_ul}>
                                <li>{t('privacyText5')}</li>
                            </ul>
                            <ul className={s.task_ul}>
                                <li>{t('privacyText6')}</li>
                            </ul>
                            <ul className={s.task_ul}>
                                <li>{t('privacyText7')}</li>
                            </ul>
                            <ul className={s.task_ul}>
                                <li>{t('privacyText8')}</li>
                            </ul>
                        </div>
                        <div className={s.about__text}>
                            <p>{t('privacyText9')}
                            </p>
                        </div>
                        <div className={s.about__text}>
                            <p>{t('privacyText10')}
                            </p>
                        </div>
                        <div className={s.about__text}>
                            <p>{t('privacyText11')}
                            </p>
                        </div>
                        <div className={s.about__text}>
                            <p>{t('privacyText12')}
                            </p>
                        </div>
                        <div className={s.about__text}>
                            <p>{t('privacyText13')}
                            </p>
                        </div>
                        <div className={s.about__text}>
                            <p>{t('privacyText14')}
                            </p>
                        </div>
                        <div className={s.about__text}>
                            <p>{t('privacyText15')}
                            </p>
                        </div>
                        <div className={s.about__text}>
                            <p>{t('privacyText16')}
                            </p>
                        </div>
                        <div className={s.about__text}>
                            <p>{t('privacyText17')}
                            </p>
                        </div>
                        <div className={s.task_list}>
                            <ul className={s.task_ul}>
                                <li>{t('privacyText18')}</li>
                            </ul>
                            <ul className={s.task_ul}>
                                <li>{t('privacyText19')}</li>
                            </ul>
                            <ul className={s.task_ul}>
                                <li>{t('privacyText20')}</li>
                            </ul>
                        </div>
                        <div className={s.about__text}>
                            <p>{t('privacyText21')}
                            </p>
                        </div>
                        <div className={s.about__text}>
                            <p>{t('privacyText22')}
                            </p>
                        </div>
                        <div className={s.about__text}>
                            <p>{t('privacyText23')}
                            </p>
                        </div>
                        <div className={s.task_list}>
                            <ul className={s.task_ul}>
                                <li>{t('privacyText24')}</li>
                            </ul>
                            <ul className={s.task_ul}>
                                <li>{t('privacyText25')}</li>
                            </ul>
                            <ul className={s.task_ul}>
                                <li>{t('privacyText26')}</li>
                            </ul>
                        </div>
                        <div className={s.about__text}>
                            <p>{t('privacyText27')}
                            </p>
                        </div>
                        <div className={s.about__text}>
                            <p>{t('privacyText28')}
                            </p>
                        </div>
                        <div className={s.about__text}>
                            <p>{t('privacyText29')}
                            </p>
                        </div>
                        <div className={s.about__text}>
                            <p>{t('privacyText30')}
                            </p>
                        </div>
                        <div className={s.about__text}>
                            <p>{t('privacyText31')}
                            </p>
                        </div>
                        <div className={s.about__text}>
                            <p>{t('privacyText32')}
                            </p>
                        </div>
                        <div className={s.about__text}>
                            <p>{t('privacyText33')}
                            </p>
                        </div>
                        <div className={s.about__text}>
                            <p>{t('privacyText34')}
                            </p>
                        </div>
                    </div>
                </div>
                <div className={s.upperCloud}></div>
                <div className={s.uuderCloud}></div>
            </div>
        </div>
    )
}