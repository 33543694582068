import { configureStore } from "@reduxjs/toolkit";
import collectionReducer from './collectionSlice'
import recognizeReducer from './recognizeSlice'
import authReducer from './authSlice'
import soundTrapsReducer from './soundTrapsSlice'

export const store = configureStore({
    reducer: {
        collection: collectionReducer,
        recognize: recognizeReducer,
        auth: authReducer,
        soundTraps: soundTrapsReducer
    },
})