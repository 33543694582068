import React from "react";
import s from './AboutUs.module.scss'
import { useTranslation } from "react-i18next"

export const AboutUs = () => {
    const { t } = useTranslation()
    return (
        <div className={s.wrapper}>
            <div className={s.wrapper_body}>
                <div className={s.container}>
                    <div className={s.about}>
                    <div className={s.about_tittle}>
                            <p>{t('aboutDev')}
                            </p>
                        </div>
                        <div className={s.about_tittle}>{t('aboutGoal')}
                        </div>
                        <div className={s.about__text}>
                            <p>{t('aboutGoalText')}
                            </p>
                        </div>
                        <div className={s.about_tittle}>{t('aboutTask')}</div>
                        <div className={s.about__text}>
                            {t('aboutTaskText')}
                        </div>
                    </div>
                </div>
                <div className={s.upperCloud}></div>
                <div className={s.uuderCloud}></div>
            </div>
        </div>
    )
}